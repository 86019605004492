<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card v-if="cycle.document.key">
      <v-card-title>
        Document attaché à un cycle
      </v-card-title>
      <v-card-text>
        <!-- Show the document -->
        <img
          v-if="cycle.document.mimeType.startsWith('image')"
          :src="cycle.document.url"
          alt="Image"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error" text
          :loading="removeLoading"
          @click="remove">
          Supprimer
        </v-btn>
        <v-btn
          color="success" text
          @click="download">
          Télécharger
        </v-btn>
        <v-spacer />
        <v-btn color="grey" text @click="close">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    cycle: {
      document: {}
    },
    removeLoading: false
  }),
  methods: {
    open(cycle) {
      this.cycle = cycle
      this.dialog = true
    },
    remove() {
      this.removeLoading = true
      this.$store.dispatch('containerCycles/removeDocument', this.cycle).then(() => {
        this.$emit('removed')
        this.close()
        this.$store.commit('alert/add', {
          type: 'success',
          text: 'Le document a été supprimé'
        })
        this.removeLoading = false
      }).catch(() => {
        this.removeLoading = false
        this.$store.commit('alert/add', {
          type: 'error',
          text: 'Impossible de supprimer le document'
        })
      })
    },
    close() {
      this.dialog = false
    },
    download() {
      let name = this.cycle.document.key
      var link = document.createElement('a')
      link.download = name
      link.target = '_blank'
      link.href = this.cycle.document.url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>