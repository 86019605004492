var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.containerCycles,"loading":_vm.tableLoading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-4",attrs:{"outlined":"","small":"","icon":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"dark":"","color":"accent"},on:{"click":_vm.createCycle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un cycle')+" ")],1)]}}])},[_c('span',[_vm._v("(Vider la benne)")])])],1)]},proxy:true},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.isCurrentCycle ? 'font-weight: bold' : '')},[_vm._v(" "+_vm._s(_vm._f("withLeadingZeros")(item.number,4))+" "),(!item.isAvailable)?_c('v-icon',{staticClass:"mb-1 ml-1",attrs:{"small":"","color":"error"}},[_vm._v(" mdi-lock-outline ")]):_vm._e()],1)]}},{key:"item.mass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mass)+" kg ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAtObject,'date'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"info"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.editForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"error"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.removeForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.uploadForm.open(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")])],1)]}}],null,true)},[_c('span',[_vm._v("Attacher un document")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!item.document || !item.document.key,"icon":"","small":"","color":"success"},on:{"click":function($event){return _vm.$refs.viewDocument.open(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir le document")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"info"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.viewReport(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Télécharger l'état")])])]}}],null,true)}),_c('EditContainerCycle',{ref:"editForm",attrs:{"container":_vm.container},on:{"finished":_vm.finished}}),_c('RemoveItem',{ref:"removeForm",attrs:{"resource":"containerCycles","title":"ce cycle","use-special":""},on:{"finished":_vm.finished}}),_c('ViewDocument',{ref:"viewDocument",on:{"removed":_vm.fetchData}}),_c('UploadForm',{ref:"uploadForm",attrs:{"mode":"container_cycles"},on:{"finished":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }