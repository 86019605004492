<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>
        <v-file-input
          truncate-length="40"
          label="Fichier à téléverser"
          ref="file"
          v-model="file"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="secondary" text
          :disabled="file == null"
          :loading="loading"
          @click="onSubmit"
        >
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    mode: { // container_cycle or elevator
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    file: null,
    resource: null
  }),
  computed: {
    formTitle() {
      if (this.mode === 'container_cycle') {
        return 'Attacher un document à un cycle de benne'
      }
      if (this.mode === 'elevator') {
        return 'Attacher un document à un ascenseur'
      }
      return ''
    }
  },
  mounted() {

  },
  methods: {
    open(item) {
      this.resource = item
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    clearForm() {
      this.file = null
    },
    onSubmit() {
      console.debug('Will upload file:', this.file)
      this.loading = true
      let form = new FormData()
      form.append('file', this.file, this.file.filename)
      let url = ''
      if (this.mode === 'container_cycles') {
        url = `/container_cycles/${this.resource.id}/document`
      }
      if (this.mode === 'elevator') {
        url = `/elevators/${this.resource.id}/documents`
      }
      this.$apitator.post(
        url,
        form,
        { withAuth: true }
      ).then(() => {
        this.loading = false
        this.$store.commit('alert/add', {
          color: 'success',
          text: 'Fichier téléversé !'
        })
        this.close()
        this.clearForm()
        this.$emit('finished')
      }).catch(() => {
        this.loading = false
        this.$store.commit('alert/add', {
          color: 'error',
          text: 'Votre fichier n\'a pas pu se téléverser'
        })
      })
    }
  }
}
</script>
