<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="containerCycles"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-4"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark color="accent" class="mb-2"
                @click="createCycle"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un cycle' }}
              </v-btn>
            </template>
            <span>(Vider la benne)</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:[`item.number`]="{ item }">
        <span
          :style="item.isCurrentCycle ? 'font-weight: bold' : ''"
        >
          {{ item.number | withLeadingZeros(4) }}
          <v-icon
            v-if="!item.isAvailable"
            class="mb-1 ml-1"
            small color="error"
          >
            mdi-lock-outline
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.mass`]="{ item }">
        {{ item.mass }} kg
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate('date') }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
            >
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
              @click="$refs.uploadForm.open(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-paperclip</v-icon>
            </v-btn>
          </template>
          <span>Attacher un document</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!item.document || !item.document.key"
              icon small color="success"
              @click="$refs.viewDocument.open(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-file-eye</v-icon>
            </v-btn>
          </template>
          <span>Voir le document</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="viewReport(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span>Télécharger l'état</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditContainerCycle
      ref="editForm"
      :container="container"
      @finished="finished"
    />
    <RemoveItem
      resource="containerCycles"
      title="ce cycle"
      ref="removeForm"
      use-special
      @finished="finished"
    />
    <ViewDocument
      ref="viewDocument"
      @removed="fetchData"
    />
    <UploadForm
      ref="uploadForm"
      mode="container_cycles"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import EditContainerCycle from "./EditContainerCycle.vue"
import RemoveItem from "@/components/RemoveItem.vue"
import UploadForm from '@/components/UploadForm'
import ViewDocument from '@/components/ContainerCycles/ViewDocument'

export default {
  components: {
    EditContainerCycle,
    RemoveItem,
    UploadForm,
    ViewDocument
  },
  props: {
    container: {
      type: Object,
      default: null,
    },
    isLinkedToContainer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableLoading: false,
      containerCycles: [],
      options: {
        sortBy: ['number'],
        sortDesc: [true],
      },
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: 'Numéro de cycle',
          value: 'number'
        },
        {
          text: 'Masse théorique',
          value: 'mass'
        },
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        if (this.isLinkedToContainer && !this.container.id) {
          return
        }
        this.fetchData()
      },
      deep: true,
    },
    container: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  methods: {
    fetchData () {
      let additionalParams = {}
      if (this.container) {
        additionalParams.container = this.container.id
      }
      this.tableLoading = true
      this.$store.dispatch(
        'containerCycles/fetchAll',
        {...this.options, additionalParams }
      ).then(res => {
        this.tableLoading = false
        this.containerCycles = res.member
        this.totalCount = res.totalItems
      })
    },
    createCycle () {
      this.$store.dispatch(
        'containerCycles/newCycle',
        this.container
      ).then(res => {
        this.$emit('cycleUpdate')
        this.fetchData()
        this.$store.commit('alert/add', {
          color: 'success',
          text: "Un cycle vient d'être créé"
        })
      }).catch(err => {
        console.error(err)
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Erreur de création de cycle"
        })
      })
    },
    viewReport (item) {
      this.$store.dispatch(
        'containerCycles/generateReport',
        item
      ).then(res => {
        window.open(res.url, '_blank')
      }).catch(err => {
        console.error(err)
        this.$store.commit('alert/add', {
          color: 'error',
          text: "Erreur de génération du rapport"
        })
      })
    },
    finished () {
      this.$emit('cycleUpdate')
      this.fetchData()
    }
  }
}
</script>
