<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <!-- <v-text-field
          v-model="container.name"
          required
          type="text"
          label="Nom de la benne">
        </v-text-field> -->
        <v-text-field
          v-model="cycle.number"
          type="number"
          label="Numéro du cycle"
        />
        <v-checkbox
          v-model="cycle.isAvailable"
          label="Ce cycle est disponible physiquement ?"
        />
        <!-- <v-select
          v-model="container.status"
          :items="availableStatues"
          label="Status"
          item-text="name"
          item-value="id" /> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="success" text
          :loading="loading"
          @click="save">
          Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rfdc from 'rfdc'
import { availableStatues } from "../../utils/constants"

export default {
  props: {
    container: {
      type: Object,
      required: true,
    } 
  },
  computed: {
    formTitle () {
      return this.mode === 'create' ? 'Nouveau cycle' : "Modification d'un cycle"
    }
  },
  data: () => ({
    mode: 'create',
    loading: false,
    dialog: false,
    cycle: {
    },
    availableStatues
  }),
  methods: {
    clearForm () {
      this.cycle = {
        number: 0,
        isAvailable: true,
      }
    },
    open (item = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode == 'edit' && this.mode == 'create') {
        this.clearForm()
      }
      if (this.mode == 'edit') {
        this.cycle = item
      }
      if (this.container) {
        this.cycle.container = { id: this.container.id }
      }
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    save () {
      this.loading = true
      let cycle = rfdc()(this.cycle)
      if (this.container) {
        cycle.container = { id: this.container.id }
      }
      if (cycle.container && cycle.container.id) {
        cycle.container = '/api/containers/' + cycle.container.id
      }
      if (cycle.wasteType && cycle.wasteType.id) {
        cycle.wasteType = '/api/waste_types/' + cycle.wasteType.id
      }
      if (this.mode === 'create') {
        this.$store.dispatch('containerCycles/create', cycle).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le cycle a bien été créé"
          })
          this.close()
          this.clearForm()
          this.$emit('finished')
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création du cycle"
          })
        })
      } else {
        this.$store.dispatch('containerCycles/update', cycle).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le cycle a bien été modifié"
          })
          this.close()
          this.$emit('finished')
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification du cycle"
          })
        })
      }
    }
  }
}
</script>
