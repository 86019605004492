<template>
  <ListContainerCycles
    :container="container"
    is-linked-to-container
    @cycleUpdate="$emit('cycleUpdate')"
  />
</template>

<script>
import ListContainerCycles from '@/components/ContainerCycles/ListContainerCycles'

export default {
  props: {
    container: {
      type: Object,
      default: null,
    },
  },
  components: { ListContainerCycles }
}
</script>
